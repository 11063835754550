var filterMethods = {
	data:{
		activeTags:[],
		$parent: {},
		$searchField: {},
		searchStrings:[]
	},

	init($parent, $searchField, $filterItems){
		filterMethods.data.$parent = $parent;
		filterMethods.data.$searchField = $searchField;
		filterMethods.makeSearchable();
		filterMethods.data.$searchField.on('input', filterMethods.handleSearchInput);
		$filterItems.on('click', filterMethods.handleClick );
	},
	makeSearchable(){
		var $items = $('.recommendation-item', filterMethods.data.$parent);
		var len = $items.length;

		for (var i = 0; i < len; i++) {
			var compound = '';
			var $item = $items.eq(i);
			compound += $item.find('.title').text();
			compound += ' ';
			compound += $item.find('.date-range').text();
			filterMethods.data.searchStrings.push(compound);
		}
	},
	handleClick(){
		var tag = $(this).attr('data-tag');
		filterMethods.toggleActive($(this), tag);
	},

	handleSearchInput() {
		var search = filterMethods.data.$searchField.val();
console.log('change :', search);
		if (search.length > 2){
			filterMethods.filterSearchWord(search);
		} else {
			var $items = $('.recommendation-item', filterMethods.data.$parent);
			$items.toggleClass('not-in-search-result', false);


		}
	},

	filterSearchWord(search){
		var tokenized = search.split(' ');
		var relevantIndexes = filterMethods.data.searchStrings.map(function(value, index){ return index});
		while (tokenized.length > 0) {
			var token = tokenized.pop();
			if (token.length < 3) {
				continue;
			}

			for (var i = relevantIndexes.length -1; i >= 0; i--) {
				var testString = filterMethods.data.searchStrings[relevantIndexes[i]];
				if (!testString.match(new RegExp(token, 'i'))) {
					relevantIndexes.splice(i, 1);
				}
			}
		}
		var $items = $('.recommendation-item', filterMethods.data.$parent);
		$items.toggleClass('not-in-search-result',true);

		for (let j=0, len = relevantIndexes.length; j <len; j++){
			$items.eq(relevantIndexes[j]).toggleClass('not-in-search-result',false);
		}

	},

	toggleActive($this, tag) {

		var index = filterMethods.data.activeTags.indexOf(tag);
		if ( index !== -1) {
			filterMethods.data.activeTags.splice(index, 1);
			$this.toggleClass('active', false);
			filterMethods.resetVisibleItems();
			return;
		}
		$this.toggleClass('active', true);
		filterMethods.data.activeTags.push(tag);
		// console.log('active tags', filterMethods.data.activeTags);
		filterMethods.resetVisibleItems();

	},
	resetVisibleItems() {
		$('.recommendation-item', filterMethods.data.$parent).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);
		var len = filterMethods.data.activeTags.length;
		if (len > 0) {
			for (var i = 0; i < len; i++) {
				$('.recommendation-item.tag-' + filterMethods.data.activeTags[i]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
			}
		} else {
			$('.recommendation-item',filterMethods.data.$parent).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
		}

		$('hiddendiv > div').unwrap();
		$('.is-flex-hidden').wrap('<hiddendiv></hiddendiv>');
	}

};


$(document).ready(function() {

	filterMethods.init($('.recommendation-items'), $('.search-field'), $('.filter .filter-item'));

	// $('.recommendation-item', filterMethods.data.$parent).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);

});