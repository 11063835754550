var newsFilterMethods = {
	data:{
		activeTags:[],
		$parent: {},
	},

	init($parent, $filterItems){
		newsFilterMethods.data.$parent = $parent;
		$filterItems.on('click', newsFilterMethods.handleClick );
	},
	handleClick(){
		var tag = $(this).attr('data-tag');
		newsFilterMethods.toggleActive($(this), tag);
	},

	toggleActive($this, tag) {

		var index = newsFilterMethods.data.activeTags.indexOf(tag);
		if ( index !== -1) {
			newsFilterMethods.data.activeTags.splice(index, 1);
			$this.toggleClass('active', false);
			newsFilterMethods.resetVisibleItems();
			return;
		}
		$this.toggleClass('active', true);
		newsFilterMethods.data.activeTags.push(tag);
		// console.log('active tags', newsFilterMethods.data.activeTags);
		newsFilterMethods.resetVisibleItems();

	},
	resetVisibleItems() {
		$('.news-item-wrapper', newsFilterMethods.data.$parent).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);
		var len = newsFilterMethods.data.activeTags.length;
		if (len > 0) {
			for (var i = 0; i < len; i++) {
				$('.news-item-wrapper.tag-' + newsFilterMethods.data.activeTags[i]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
			}
		} else {
			$('.news-item-wrapper',newsFilterMethods.data.$parent).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
		}

		$('hiddendiv > div').unwrap();
		$('.is-flex-hidden').wrap('<hiddendiv></hiddendiv>');

	}

};


$(document).ready(function() {
	newsFilterMethods.init($('.news-items'), $('.filter .filter-item'));
});